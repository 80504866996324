@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .lineDivisor {
    display: flex;
      align-items: center;
      margin: 40px auto 52px;
  }
  .lineDivisor::after {
    content: '';
    display: block;
    width: 150px;
    height: 0.5px;
    margin-left: 20px;
    background-color: theme('colors.neutral');
  }
  .lineDivisor::before {
    content: '';
    display: block;
    width: 150px;
    height: 0.5px;
    margin-right: 20px;
    background-color: theme('colors.neutral');
  }

}

body {
  margin: 0;
  font-family: muli, sans-serif;;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: theme('colors.neutral');
}

a {
  color: theme('colors.primary');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
