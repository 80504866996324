.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 10px;
    height: 10px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    -webkit-text-indent: -999px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 5px;
    opacity: 0.5;
    transition: opacity 0.6s ease;
}

.carousel-indicators .active {
    opacity: 1;
    width: 20px;
}